<template>
    <LeaveOfAbsence clientCode="WCG"></LeaveOfAbsence>
</template>
<script>
import LeaveOfAbsence from "@/components/shared/LOA/LeaveOfAbsence";
require('@/assets/css/client/WCG/wcg_variables.scss');

export default {
  components: { LeaveOfAbsence },
  name: "WCGLeaveOfAbsence",
};
</script>
<style lang="scss" scoped src="@/assets/css/client/WCG/wcgloa.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/WCG/checklist_styling.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/WCG/sample_language.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/WCG/bar_chart.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/WCG/feedback_modal.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/WCG/send_email_modal.scss"></style>